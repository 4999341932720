import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useBackgroundStyleConfigs} from 'hooks/useBackgroundStyleConfigs';
import Image from 'next/image';
import {useRouter} from 'next/router';
import React, {FC, useCallback, useContext, useEffect, useState} from 'react';

import {useMediaQuery} from '@mui/material';

import {getApiV1GroupsBatch, getApiV1GroupsByType} from 'lib/api/backend';
import {Group} from 'lib/api/backend.schemas';
import {TypoComponentsCarTeaserSection} from 'lib/api/strapi';

import {bestPriceGroupCardFlip} from 'utils/tagManagerHelpers/gtmEventsParsing';

import {palette} from 'styles/mui/scss';

import {Box, Typography} from 'components/basic-components';
import {ButtonWithConfig} from 'components/basic-components/Button/ButtonWithConfig';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import {ConsentContext} from 'components/section-components/ConsentManager';
import BPRModal from 'components/section-components/bpr/BPRModal/BPRModal';

import styles from './CarTeaser.module.scss';
import CarTeaserMobile from './CarTeaserMobile';

const CarTeaserContent = ({title, subtitle, titleLevel, subTitleLevel, button}) => {
  return (
    <>
      <Typography component={titleLevel} variant="h1" className={styles.title}>
        {title}
      </Typography>
      <Typography component={subTitleLevel} variant="h2" className={styles.subtitle}>
        {subtitle}
      </Typography>
      <ButtonWithConfig buttonProps={button} sx={{marginTop: '20px'}} />
    </>
  );
};

const CarTeaser: FC<TypoComponentsCarTeaserSection> = ({
  title,
  titleLevel,
  subtitle,
  subTitleLevel,
  image,
  button,
  groups,
  type,
  groupsLink,
}) => {
  const isDesktop = useMediaQuery('(min-width:850px)');
  const [groupsData, setGroupsData] = useState([]);
  const {t} = useTranslation('strapiComponents');
  const {theme} = useBackgroundStyleConfigs();
  const {
    link: groupsLinkHref = '',
    page: groupsLinkPage,
    newWindow: groupsLinkNewWindow,
  } = groupsLink || {};

  const [activeGroup, setActiveGroup] = useState<Group>({});
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {push} = useRouter();
  const {pushDataLayer} = useContext(ConsentContext);

  const clickGroupCard = useCallback(() => {
    pushDataLayer(bestPriceGroupCardFlip(activeGroup, 'bpr_cts'));
  }, [activeGroup]);

  useEffect(() => {
    const groupsIds = groups?.map(obj => obj.group).join(',');
    const getGroupsByType = async () => {
      if (type?.type && !groupsIds) {
        const response = await getApiV1GroupsByType(type?.type, {withImages: true, limit: 4});
        setGroupsData(response);
      }
    };
    const getGroupsByGroups = async () => {
      if (groupsIds) {
        const response = await getApiV1GroupsBatch({groupIds: groupsIds, withImages: true});
        setGroupsData(response);
      }
    };
    getGroupsByType();
    getGroupsByGroups();
  }, [groups, type?.type]);

  let chipActive = false;

  groupsData?.some(dataItem => {
    if (dataItem.electro === true || dataItem.powerInclusive === true) {
      chipActive = true;
      return true;
    }
    return false;
  });

  const handleCTA = group => {
    if (groupsLink && (groupsLinkPage?.data?.attributes?.fullSlug || groupsLinkHref)) {
      if (groupsLinkNewWindow) {
        window.open(groupsLinkPage?.data?.attributes?.fullSlug || groupsLinkHref, '_blank');
      } else {
        push(groupsLinkPage?.data?.attributes?.fullSlug || groupsLinkHref);
      }
    } else {
      setActiveGroup(group);
      setModalOpen(true);
      clickGroupCard();
    }
  };

  if (Array.isArray(groupsData) && !groupsData?.length) {
    return null;
  }
  return (
    <Box sx={{padding: '50px 0'}}>
      <BPRModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        presetGroup={activeGroup?.id}
        presetType={activeGroup?.type}
        minDate={activeGroup?.bookableFrom}
        maxDate={activeGroup?.bookableTo}
        presetCategory={activeGroup?.category}
        presetVip={activeGroup?.vip}
        hideOneWay={!activeGroup?.oneWay}
        isTeaser={true}
      />
      {!isDesktop ? (
        <CarTeaserMobile
          groups={groupsData}
          title={title}
          titleLevel={titleLevel}
          image={image}
          subtitle={subtitle}
          subTitleLevel={subTitleLevel}
          button={button}
          handleCTA={handleCTA}
        />
      ) : (
        <Box className={styles.wrapper}>
          <Box className={styles.imageWrapper}>
            <Box className={styles.background} />
            <Image
              className={styles.image}
              src={image?.data?.attributes?.url}
              alt=""
              height={image?.data?.attributes?.height}
              width={image?.data?.attributes?.width}
            />
            <Box className={styles.imageContentWrapper}>
              <CarTeaserContent
                title={title}
                subtitle={subtitle}
                subTitleLevel={subTitleLevel}
                titleLevel={titleLevel}
                button={button}
              />
            </Box>
          </Box>
          <Box className={styles.carGroupsWrapper}>
            {groupsData?.map(group => {
              return (
                <button
                  key={group?.id}
                  aria-label={`${t('group')} ${group?.id}`}
                  className={styles.groupWrapper}
                  style={{
                    backgroundColor: theme === 'grey' ? palette.colorWhite : palette.colorLightGrey,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCTA(group)}
                >
                  <Box sx={{padding: '15px 15px 18px 15px'}}>
                    <Box sx={{height: chipActive ? '25px' : null}} className={styles.chips}>
                      {group?.electro ? (
                        <Typography className={styles.electro} variant="chip">
                          {t('electric')}
                        </Typography>
                      ) : null}
                      {group?.powerInclusive ? (
                        <Typography className={styles.electricity} variant="chip">
                          {t('electricityIncluded')}
                        </Typography>
                      ) : null}
                    </Box>
                    <Image
                      className={styles.groupImage}
                      src={group?.image?.small}
                      width={500}
                      height={500}
                      alt=""
                    />
                    <Typography sx={{lineHeight: '24px', textAlign: 'start'}}>
                      {t('group')} {group?.id}
                    </Typography>
                    <Box
                      style={{
                        width: 'fit-content',
                        display: 'block',
                      }}
                    >
                      <Typography
                        variant="buttonCards"
                        sx={{
                          lineHeight: '24px',
                          display: 'block',
                          width: 'fit-content',
                          textAlign: 'start',
                        }}
                      >
                        {t('forExample')} {group?.description}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        position: 'absolute',
                        bottom: '16px',
                        right: '10px',
                        width: '24px',
                        height: '24px',
                      }}
                    >
                      <CustomIcon name="arrow" className={styles.icon} />
                    </Box>
                  </Box>
                </button>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withErrorBoundary(CarTeaser, ' CarTeaser');
